import React, {useState, useCallback, useRef} from 'react'
import "./PDFViewer.css"
import "@cyntler/react-doc-viewer/dist/index.css";
import HTMLFlipBook from 'react-pageflip';
import { pdfjs, Document, Page as ReactPdfPage } from 'react-pdf';


import { FaArrowLeft } from "react-icons/fa6";
import { FaArrowRight } from "react-icons/fa6";


pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const width = 300;
const height = width * 1.41;


const Page = React.forwardRef(({ pageNumber }, ref) => {
    return (
      <div ref={ref}>
        <ReactPdfPage pageNumber={pageNumber} width={width} height={height} loading={ <div className='PDFLoaderContainer'><div className="PDFloader"></div></div>}/>
      </div>
    );
});

function PDFViewer({doc, title, filename}) {
    const [numPages, setNumPages] = useState();
    const [pageNumber, setPageNumber] = useState(1);

  const book = useRef();

  let pages = [];

  for (let i = 1; i <= numPages; i++) {
    pages.push(i);
  }


  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function handlePrev(){
    if(pages.length > 1){
      book.current.pageFlip().flipPrev()
      // if(pageNumber !== 1){
      //   setPageNumber(pageNumber - 1)
      // }
    }
  }

  function handleNext(){ 
    if(pages.length > 1){
      book.current.pageFlip().flipNext()
      // setPageNumber(pageNumber + 1)
    }
  }

    const onFlip = useCallback((e) => {
      if(pageNumber){
        setPageNumber(book.current.pageFlip().getCurrentPageIndex() + 1);
      }
    }, [pageNumber]);


    return (
        <>
            <div className='container mt-5 pt-5 mb-5 pb-5 PDFViewerContainer'>
                <h1>{title}</h1>
                 <Document file={doc}  
                 onLoadSuccess={onDocumentLoadSuccess} 
                 error="Try Reloading the page"
                 loading={ <div className='PDFLoaderContainer'><div className="PDFloader"></div></div>}
                 >
                    <HTMLFlipBook 
                    width={width} 
                    height={height} 
                    ref={book}
                    showCover={false}
                    onFlip={onFlip}
                    maxShadowOpacity={0.5}
                    mobileScrollSupport={true}
                    >
                        {
                            pages.map((page, index) => (
                                <Page key={index} pageNumber={page} />
                            ))
                        }
                    </HTMLFlipBook>
                </Document>
                <div className="buttonAndPageNum">
                    <button onClick={handlePrev} ><FaArrowLeft /></button>
                    <p>Page {pageNumber} of {numPages}</p>
                    <button onClick={handleNext} ><FaArrowRight /></button>
                </div>
                <a href={doc} download={`${filename}.pdf`}>Download</a>
            </div>
        </>
      )
}

export default PDFViewer