import React from 'react'
import Navbar from '../About-us/Navbar/navbar';
// import Footer from '../pages/vector/commonfooter/footer';
import Footer from '../../sections/Footer/Footer';
import "./Profile.css";
import ContactUsForm from '../SwitchToMac/ContactUsForm';
import PDFViewer from "../../sections/PDFViewer/PDFViewer";


import DLProfile from "../../assets/data/DL_Profile.pdf"


function Profile() {
  return (
    <>
        <Navbar />
        <PDFViewer doc={DLProfile} title="Company Profile" filename="Datalogics Company Profile" />
        <ContactUsForm ctaMain="Contact us!"  ctaPara="Explore and find the most effective solution tailored to meet the unique needs of your business." subject="Company Profile page Form Response"  />
        <Footer />
    </>
  )
}

export default Profile