// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.formContainer h2{
    text-align: center;
}

.formContainer form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.formContainer form input{
    width: 100%;
    max-width: 500px;
    height: 3rem;
    border: none;
    border-radius: 0.5rem;
    background-color: #efefef;
    padding: 1rem;
}

.formContainer form input::placeholder{
   color: #b8b7b7;
}

.formContainer form input[type="submit"]{
    width: 100%;
    max-width: 250px;
    height: 3rem;
    border: none;
    border-radius: 2rem;
    background-color: #d80128;
    color: #ffffff;
    padding: 0rem;
}

.PDFViewermain{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.PDFViewer{
    width: 100%;
    border: 5px solid red;
    overflow: scroll;
    border-radius: 1rem;
    padding: 2rem;
}


.dowwnload-btn{
    background-color: #d80128;
    border-radius: 1rem;
    color: #ffffff;
    text-decoration: none;
    padding: 0.5rem 1rem;
    text-align: center;
    transition: all 300ms ease-in-out;
}

.dowwnload-btn:hover{
    color: #d80128;
    background-color: #ffffff;
}`, "",{"version":3,"sources":["webpack://./src/components/FormLead/FormLead.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,SAAS;AACb;;AAEA;IACI,WAAW;IACX,gBAAgB;IAChB,YAAY;IACZ,YAAY;IACZ,qBAAqB;IACrB,yBAAyB;IACzB,aAAa;AACjB;;AAEA;GACG,cAAc;AACjB;;AAEA;IACI,WAAW;IACX,gBAAgB;IAChB,YAAY;IACZ,YAAY;IACZ,mBAAmB;IACnB,yBAAyB;IACzB,cAAc;IACd,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,WAAW;IACX,qBAAqB;IACrB,gBAAgB;IAChB,mBAAmB;IACnB,aAAa;AACjB;;;AAGA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,cAAc;IACd,qBAAqB;IACrB,oBAAoB;IACpB,kBAAkB;IAClB,iCAAiC;AACrC;;AAEA;IACI,cAAc;IACd,yBAAyB;AAC7B","sourcesContent":[".formContainer h2{\n    text-align: center;\n}\n\n.formContainer form{\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    gap: 1rem;\n}\n\n.formContainer form input{\n    width: 100%;\n    max-width: 500px;\n    height: 3rem;\n    border: none;\n    border-radius: 0.5rem;\n    background-color: #efefef;\n    padding: 1rem;\n}\n\n.formContainer form input::placeholder{\n   color: #b8b7b7;\n}\n\n.formContainer form input[type=\"submit\"]{\n    width: 100%;\n    max-width: 250px;\n    height: 3rem;\n    border: none;\n    border-radius: 2rem;\n    background-color: #d80128;\n    color: #ffffff;\n    padding: 0rem;\n}\n\n.PDFViewermain{\n    display: flex;\n    flex-wrap: wrap;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n}\n\n.PDFViewer{\n    width: 100%;\n    border: 5px solid red;\n    overflow: scroll;\n    border-radius: 1rem;\n    padding: 2rem;\n}\n\n\n.dowwnload-btn{\n    background-color: #d80128;\n    border-radius: 1rem;\n    color: #ffffff;\n    text-decoration: none;\n    padding: 0.5rem 1rem;\n    text-align: center;\n    transition: all 300ms ease-in-out;\n}\n\n.dowwnload-btn:hover{\n    color: #d80128;\n    background-color: #ffffff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
