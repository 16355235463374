import React, {useState} from 'react'
import Calendar from 'react-calendar'
import "./AppointmentCalender.css"
import 'react-calendar/dist/Calendar.css';
import moment from 'moment';

function AppointmentCalender() {

    const [date, setDate] = useState()
    // const [data, setData] = useState("")




  return (
    <div className='Calendar-container'>
        <Calendar 
        view='month'
        value={date} 
        onChange={setDate}
        minDate={new Date(Date.now())}
         tileDisabled={({ activeStartDate, date, view }) => date.getDay() === 0 || date.getDay() === 6}
        />
        <h3>{moment(date).format('MMMM Do YYYY')}</h3>
    </div>
  )
}

export default AppointmentCalender