// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./assets/fonts/Gilroy Font/Gilroy-Light.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./assets/fonts/Gilroy Font/Gilroy-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./assets/fonts/Gilroy Font/Gilroy-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./assets/fonts/Gilroy Font/Gilroy-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("./assets/fonts/Gilroy Font/Gilroy-SemiBold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("./assets/fonts/Poppins/Poppins-Light.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: Gilroy-Light;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
@font-face {
  font-family: Gilroy-Regular;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}
@font-face {
  font-family: Gilroy-Medium;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
}
@font-face {
  font-family: Gilroy-Bold;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___});
}
@font-face {
  font-family: Gilroy-SemiBold;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_4___});
}
@font-face {
  font-family: Poppins-Light;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_5___});
}



*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;

}
/* body::-webkit-scrollbar{
  display: none;
} */

html {
  scroll-behavior: smooth;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,4CAAwD;AAC1D;AACA;EACE,2BAA2B;EAC3B,4CAA0D;AAC5D;AACA;EACE,0BAA0B;EAC1B,4CAAyD;AAC3D;AACA;EACE,wBAAwB;EACxB,4CAAuD;AACzD;AACA;EACE,4BAA4B;EAC5B,4CAA2D;AAC7D;AACA;EACE,0BAA0B;EAC1B,4CAAoD;AACtD;;;;AAIA;EACE,UAAU;EACV,SAAS;EACT,sBAAsB;;AAExB;AACA;;GAEG;;AAEH;EACE,uBAAuB;AACzB","sourcesContent":["@font-face {\n  font-family: Gilroy-Light;\n  src: url(\"./assets/fonts/Gilroy\\ Font/Gilroy-Light.ttf\");\n}\n@font-face {\n  font-family: Gilroy-Regular;\n  src: url(\"./assets/fonts/Gilroy\\ Font/Gilroy-Regular.ttf\");\n}\n@font-face {\n  font-family: Gilroy-Medium;\n  src: url(\"./assets/fonts/Gilroy\\ Font/Gilroy-Medium.ttf\");\n}\n@font-face {\n  font-family: Gilroy-Bold;\n  src: url(\"./assets/fonts/Gilroy\\ Font/Gilroy-Bold.ttf\");\n}\n@font-face {\n  font-family: Gilroy-SemiBold;\n  src: url(\"./assets/fonts/Gilroy\\ Font/Gilroy-SemiBold.ttf\");\n}\n@font-face {\n  font-family: Poppins-Light;\n  src: url(\"./assets/fonts/Poppins/Poppins-Light.ttf\");\n}\n\n\n\n*{\n  padding: 0;\n  margin: 0;\n  box-sizing: border-box;\n\n}\n/* body::-webkit-scrollbar{\n  display: none;\n} */\n\nhtml {\n  scroll-behavior: smooth;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
