import React from 'react'
import "./Loading.css"

function Loading() {
  return (
    <div className='LoadingContainer container'>
        {/* <h2>Loading...</h2> */}
        <div className="loader"></div>
    </div>
  )
}

export default Loading