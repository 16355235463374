// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-menu{
    /* height: 100%;
    min-height: 80svh; */
    width: 100%;
}

.enterprise-footer p {
    color : white;
}

.enterprise-footer .row > div > div > p:first-child {
    font-weight: bold;
}

.font-normal{
    color: #bcb8b8 !important;
    font-size: 14px;
    transition: all 200ms ease;
}

.font-normal:hover{
    color: #ffffff !important;
}

.social-icons{
    width: 100% !important;
    justify-content: center !important;
    align-items: center;
    margin: 0;
    padding: 0;
}

.social-icons>a>img{
    width: 90%;
    transition: all 250ms ease-in-out;
    padding: 0.5rem;
}

.copyright>p{
    font-size: 14px;
}

.newsletter-subscribe-container{
    scale: 0.8;
    margin-left: -20px;
    width: 100%;
    max-width: 540px;
}

`, "",{"version":3,"sources":["webpack://./src/components/pages/vector/commonfooter/footer.css"],"names":[],"mappings":"AAAA;IACI;wBACoB;IACpB,WAAW;AACf;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,yBAAyB;IACzB,eAAe;IACf,0BAA0B;AAC9B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,sBAAsB;IACtB,kCAAkC;IAClC,mBAAmB;IACnB,SAAS;IACT,UAAU;AACd;;AAEA;IACI,UAAU;IACV,iCAAiC;IACjC,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,UAAU;IACV,kBAAkB;IAClB,WAAW;IACX,gBAAgB;AACpB","sourcesContent":[".footer-menu{\n    /* height: 100%;\n    min-height: 80svh; */\n    width: 100%;\n}\n\n.enterprise-footer p {\n    color : white;\n}\n\n.enterprise-footer .row > div > div > p:first-child {\n    font-weight: bold;\n}\n\n.font-normal{\n    color: #bcb8b8 !important;\n    font-size: 14px;\n    transition: all 200ms ease;\n}\n\n.font-normal:hover{\n    color: #ffffff !important;\n}\n\n.social-icons{\n    width: 100% !important;\n    justify-content: center !important;\n    align-items: center;\n    margin: 0;\n    padding: 0;\n}\n\n.social-icons>a>img{\n    width: 90%;\n    transition: all 250ms ease-in-out;\n    padding: 0.5rem;\n}\n\n.copyright>p{\n    font-size: 14px;\n}\n\n.newsletter-subscribe-container{\n    scale: 0.8;\n    margin-left: -20px;\n    width: 100%;\n    max-width: 540px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
