// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about-us.education-nav-bg li .nav-link{
    color: black;
    /* color: var(--color); */
    font-weight: bold;
    font-family: Gilroy-Regular;
}

.about-us.education-nav-bg li .nav-link:hover {
    color: rgba(0,0,0,0.5);
}


.navigation-extension {
    position: relative;
    display: flex;
    /* justify-content: space-between; */
}

.navigation-extension .navigation-expansion {
    position: absolute;
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    top: 100%;
    left: 3%;
}

.navigation-up-icon {
    position: relative;
}

.navigation-up-icon img {
    position: absolute;
    top: -35px;
    left: 45%;
}

.navigation-tabs p {
    text-align: center;
}

.navigation-tabs p:hover{
    font-weight: bold;
    cursor: pointer;
}

.nav-bg {
    background-color: #1a1a1a;
    /* background-color: var(--backgroundColor); */
}`, "",{"version":3,"sources":["webpack://./src/components/About-us/Navbar/navbar.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,yBAAyB;IACzB,iBAAiB;IACjB,2BAA2B;AAC/B;;AAEA;IACI,sBAAsB;AAC1B;;;AAGA;IACI,kBAAkB;IAClB,aAAa;IACb,oCAAoC;AACxC;;AAEA;IACI,kBAAkB;IAClB,sBAAsB;IACtB,mBAAmB;IACnB,aAAa;IACb,SAAS;IACT,QAAQ;AACZ;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,SAAS;AACb;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,yBAAyB;IACzB,8CAA8C;AAClD","sourcesContent":[".about-us.education-nav-bg li .nav-link{\n    color: black;\n    /* color: var(--color); */\n    font-weight: bold;\n    font-family: Gilroy-Regular;\n}\n\n.about-us.education-nav-bg li .nav-link:hover {\n    color: rgba(0,0,0,0.5);\n}\n\n\n.navigation-extension {\n    position: relative;\n    display: flex;\n    /* justify-content: space-between; */\n}\n\n.navigation-extension .navigation-expansion {\n    position: absolute;\n    background-color: #fff;\n    border-radius: 10px;\n    padding: 20px;\n    top: 100%;\n    left: 3%;\n}\n\n.navigation-up-icon {\n    position: relative;\n}\n\n.navigation-up-icon img {\n    position: absolute;\n    top: -35px;\n    left: 45%;\n}\n\n.navigation-tabs p {\n    text-align: center;\n}\n\n.navigation-tabs p:hover{\n    font-weight: bold;\n    cursor: pointer;\n}\n\n.nav-bg {\n    background-color: #1a1a1a;\n    /* background-color: var(--backgroundColor); */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
