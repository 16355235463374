// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LoadingContainer{
    display: grid;
    place-items: center;
    height: 100svh;
}

.loader {
    height: 4px;
    width: 130px;
    --c:no-repeat linear-gradient(#d80128 0 0);
    background: var(--c),var(--c),#d8012863;
    background-size: 60% 100%;
    animation: l16 3s infinite;
  }
  @keyframes l16 {
    0%   {background-position:-150% 0,-150% 0}
    66%  {background-position: 250% 0,-150% 0}
    100% {background-position: 250% 0, 250% 0}
  }`, "",{"version":3,"sources":["webpack://./src/components/Loading/Loading.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,cAAc;AAClB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,0CAA0C;IAC1C,uCAAuC;IACvC,yBAAyB;IACzB,0BAA0B;EAC5B;EACA;IACE,MAAM,mCAAmC;IACzC,MAAM,mCAAmC;IACzC,MAAM,mCAAmC;EAC3C","sourcesContent":[".LoadingContainer{\n    display: grid;\n    place-items: center;\n    height: 100svh;\n}\n\n.loader {\n    height: 4px;\n    width: 130px;\n    --c:no-repeat linear-gradient(#d80128 0 0);\n    background: var(--c),var(--c),#d8012863;\n    background-size: 60% 100%;\n    animation: l16 3s infinite;\n  }\n  @keyframes l16 {\n    0%   {background-position:-150% 0,-150% 0}\n    66%  {background-position: 250% 0,-150% 0}\n    100% {background-position: 250% 0, 250% 0}\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
